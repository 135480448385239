import React, { Component } from "react";
import PaceCalculator from "./paceCalculator";

class App extends Component {
	render() {
		return (
			<>
				<div className="heading">
					<h1>Run.Pace</h1>
					<span className="color-default">Simple. Pace. Calculator.</span>
				</div>
				<div className="text-info">
					<span className="color-default">
						Use Calculator By Inputting Two Values
					</span>
					<br />
					<span className="color-default">
						Running Pace = <span className="color-pink">Time </span> /
						<span className="color-pink"> Distance</span> <br />
						Finish Time = <span className="color-pink">Distance </span>*
						<span className="color-pink"> Pace</span>
					</span>
				</div>
				<div className="pace-forms">
					<PaceCalculator />
				</div>
				<div className="text-info">
					<h2>
						Run<span className="color-default">dot</span>Pace Calculator
					</h2>
					<span className="color-default">
						Use the Run <span className="color-pink">dot</span> Pace Calculator
						to determine your running pace for any distance. Or use your running
						pace to determine your expected finish time for your next race.
					</span>
				</div>
				<span className="footer" role="img" aria-label="running">
					Created for the 💛 of ‍running
				</span>
			</>
		);
	}
}
export default App;
