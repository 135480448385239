import React from "react";
import Switch from "rc-switch";
import PaceForm from "./pace-form";

class PaceCalculator extends PaceForm {
	state = {
		run: {
			distance: "",
		},
		update: {
			field: null,
		},
		time: {
			hour: "",
			minute: "",
			second: "",
		},
		pace: {
			minute: "",
			second: "",
		},
		unit: {
			metric: "true",
		},
	};

	handleSubmit = (e) => {
		e.preventDefault();
	};

	getBorderColor(field) {
		let classes = `user-input`;
		let calcField = this.state.update.field;
		if (calcField === field) {
			classes = "calculated-field";
		}
		return classes;
	}

	render() {
		return (
			<>
				<div className="pace-form">
					<form onSubmit={this.handleSubmit} autoComplete="off">
						<button style={{ display: "none" }}>Run</button>

						<div className="form-header">
							<div>
								{this.state.update.field ? (
									<div className="reset-button">
										<button className="reset" onClick={this.handleReset}>
											RESET
										</button>
									</div>
								) : null}
							</div>
							<div className="align-left">
								<span className="color-light">Switch unit:</span>
								<Switch
									onChange={this.handleChange}
									disabled={false}
									checkedChildren="km"
									unCheckedChildren="mi"
									defaultChecked={true}
								/>
							</div>
						</div>

						<label htmlFor="distance" className="label-mix">
							<strong>Distance</strong>
							{this.state.unit.metric === false
								? " in kilometers"
								: " in miles"}
						</label>
						<div className="row">
							<div className="column">
								<input
									value={this.state.run.distance}
									type="number"
									name="distance"
									id="distance"
									onChange={this.handleDistance}
									min="0"
									max="100000"
									pattern="[0-9]*"
									inputMode="decimal"
									className={this.getBorderColor("distance")}
								/>
							</div>
						</div>
						<div className="label-bold">Time</div>
						<div className="row">
							<div className="column">
								<label className="label-light" htmlFor="hour">
									hours
								</label>
								<input
									value={this.state.time.hour}
									type="number"
									name="hour"
									id="hour"
									onChange={this.handleTime}
									min="0"
									max="100000"
									pattern="[0-9]*"
									inputMode=""
									className={this.getBorderColor("time")}
								/>
							</div>
							<div className="column">
								<label className="label-light" htmlFor="minute">
									minutes
								</label>
								<input
									value={this.state.time.minute}
									type="number"
									name="minute"
									id="minute"
									onChange={this.handleTime}
									min="0"
									max="100000"
									pattern="[0-9]*"
									inputMode="decimal"
									className={this.getBorderColor("time")}
								/>
							</div>

							<div className="column">
								<label className="label-light" htmlFor="second">
									seconds
								</label>
								<input
									value={this.state.time.second}
									type="number"
									name="second"
									id="second"
									onChange={this.handleTime}
									min="0"
									max="100000"
									pattern="[0-9]*"
									inputMode="decimal"
									className={this.getBorderColor("time")}
								/>
							</div>
						</div>

						<div className="label-bold">Pace </div>
						<div className="row">
							<div className="column">
								<label className="label-light" htmlFor="paceMinute">
									{this.state.unit.metric === false ? " min/km" : " min/mile"}
								</label>
								<input
									value={this.state.pace.minute}
									type="number"
									name="minute"
									id="paceMinute"
									onChange={this.handlePace}
									min="0"
									max="100000"
									pattern="[0-9]*"
									inputMode="decimal"
									className={this.getBorderColor("pace")}
								/>
							</div>
							<div className="column">
								<label className="label-light" htmlFor="paceSecond">
									{this.state.unit.metric === false ? " sec/km" : " sec/mile"}
								</label>
								<input
									value={this.state.pace.second}
									type="number"
									name="second"
									id="paceSecond"
									onChange={this.handlePace}
									min="0"
									max="100000"
									pattern="[0-9]*"
									inputMode="decimal"
									className={this.getBorderColor("pace")}
								/>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default PaceCalculator;
