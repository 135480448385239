import { Component } from "react";

class PaceForm extends Component {
	state = {
		run: {},
		update: {},
		time: {},
		pace: {},
		unit: {},
	};

	pace = 0;
	time = 0;
	distance = 0;
	active = [
		["distance", 0],
		["time", 0],
		["pace", 0],
	];

	handleChange = (value) => {
		const unit = this.state.unit;
		unit.metric = value;
		this.setState({ unit }, () => {
			this.handleUnitChange(value);
		});
	};

	handleUnitChange = (value) => {
		const run = { ...this.state.run };
		const distance = run.distance;
		if (distance <= 0) {
			return null;
		} else if (value === true) {
			const miles = Number((distance * 1000) / 1609.344).toFixed(4);
			run.distance = miles;
			if (this.time > 0) {
				this.pace = this.time / run.distance;
				this.changePaceTimeToMin(this.pace);
			}
		} else if (value === false) {
			const km = Number((distance / 1000) * 1609.344).toFixed(4);
			run.distance = parseFloat(km);
			if (this.time > 0) {
				this.pace = this.time / run.distance;
				this.changePaceTimeToMin(this.pace);
			}
		}
		this.setState({ run });
	};

	handleReset = () => {
		const run = { ...this.state.run };
		const pace = { ...this.state.pace };
		const time = { ...this.state.time };
		const update = { ...this.state.update };
		run.distance = "";
		update.field = "";
		this.time = 0;
		this.pace = 0;
		this.distance = 0;
		this.active[0][1] = 0;
		this.active[1][1] = 0;
		this.active[2][1] = 0;
		time.hour = "";
		time.minute = "";
		time.second = "";
		pace.minute = "";
		pace.second = "";
		this.setState({ run, time, pace, update });
	};

	fieldToUpdate = (name, value) => {
		const active = [...this.active];
		const update = { ...this.state.update };

		if (name === "distance") {
			this.distance = value;
		} else if (name === "time") {
			this.time = value;
		} else if (name === "pace") {
			this.pace = value;
		}

		let activeSort = active.sort((a, b) => a[1] - b[1]);
		let updateField = activeSort[0][0];

		if (value <= 0) {
			update.field = "";
			activeSort[2][1] = 0;
			if (updateField === "pace") {
				this.changePaceTimeToMin(0);
			}
			if (updateField === "time") {
				this.changeRunTimeToMin(0);
				updateField = "";
			}
			if (updateField === "distance") {
				this.changeDistance(0);
				updateField = "";
			}
		}

		if (updateField === "pace" && this.time > 0 && this.distance > 0) {
			this.pace = this.time / this.distance;
			this.changePaceTimeToMin(this.pace);
			update.field = updateField;
		} else if (updateField === "time" && this.pace > 0 && this.distance > 0) {
			this.time = this.distance * this.pace;
			this.changeRunTimeToMin(this.time);
			update.field = updateField;
		} else if (updateField === "distance" && this.pace > 0 && this.time > 0) {
			this.distance = Number((this.time / this.pace).toFixed(2));
			this.changeDistance(this.distance);
			update.field = updateField;
		}
		this.setState({ update });
	};

	handleDistance = (e) => {
		const { name, value } = e.target;
		const run = { ...this.state.run };
		run[name] = value;
		this.isActive(0);
		this.setState({ run }, () => {
			this.fieldToUpdate("distance", Number(value));
		});
	};

	changeDistance = (value) => {
		const run = { ...this.state.run };
		run.distance = Number(value);
		if (run.distance <= 0) {
			run.distance = "";
			this.distance = 0;
		}
		this.setState({ run });
	};

	handlePace = (e) => {
		const { name, value } = e.target;
		const pace = { ...this.state.pace };
		pace[name] = value;

		let minute = pace.minute ? +pace.minute : 0;
		let second = pace.second ? pace.second / 60 : 0;
		let values =
			name === "minute" ? Number(value) + second : pace.second / 60 + minute;

		this.isActive(2);
		this.setState({ pace }, () => {
			this.fieldToUpdate("pace", values);
		});
	};

	changePaceTimeToMin = (minutes) => {
		const pace = { ...this.state.pace };

		let hour = minutes >= 60 ? Math.floor(minutes / 60) : 0;
		let minute = minutes > 0 ? Math.floor(minutes % 60) + hour * 60 : 0;
		let second = minutes > 0 ? Math.floor((minutes - minute) * 60) : 0;

		pace.minute = parseInt(minute, 10);
		pace.second = parseInt(second, 10);

		if (pace.minute <= 0 && pace.second <= 0) {
			pace.minute = "";
			pace.second = "";
			this.pace = 0;
		}

		this.setState({ pace });
	};

	handleTime = (e) => {
		const { name, value } = e.target;
		const time = { ...this.state.time };
		time[name] = value;

		let minute = time.minute > 0 ? +time.minute : 0;
		let hour = time.hour > 0 ? Math.floor(time.hour * 60) : 0;
		let second = time.second > 0 ? time.second / 60 : 0;
		let totalMinutes = 0;

		if (name === "minute") {
			totalMinutes = Number(value) + hour + second;
		} else if (name === "hour") {
			totalMinutes = Math.floor(value * 60) + minute + second;
		} else if (name === "second") {
			totalMinutes = time.second / 60 + hour + minute;
		}

		this.isActive(1);
		this.setState({ time }, () => {
			this.fieldToUpdate("time", totalMinutes);
		});
	};

	changeRunTimeToMin = (minutes) => {
		const time = { ...this.state.time };
		let hour = minutes >= 60 ? Math.floor(minutes / 60) : 0;
		let minute = minutes > 0 ? Math.floor(minutes % 60) : 0;
		let second =
			minutes > 0 ? Math.floor((minutes - (hour * 60 + minute)) * 60) : 0;

		time.hour = hour;
		time.minute = minute;
		time.second = second;

		if (time.minute <= 0 && time.second <= 0 && time.hour <= 0) {
			time.minute = "";
			time.second = "";
			time.hour = "";
			this.time = 0;
		}

		this.setState({ time });
	};

	isActive = (num) => {
		let sum = this.active[0][1] + this.active[1][1] + this.active[2][1];
		this.active[num][1] = sum + 1;
	};
}

export default PaceForm;
